@charset "UTF-8";

html {
  font-family: century-gothic, sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
  line-height: 1.25;
}

body, .webkit-modules{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

body, .webkit-modules{
  font-family: century-gothic, sans-serif;
}

.modular-block {
  position: relative;
}

.webkit-spacing-first {
  padding-top: 0px;
  padding-bottom: 0px;
}

@media (max-width: 1200px){
  .webkit-spacing-first{
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 768px){
  .webkit-spacing-first{
    padding-bottom: 0px;
  }
}

.webkit-spacing-normal {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1200px){
  .webkit-spacing-normal{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 480px){
  .webkit-spacing-normal{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-redactor-content {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content{
    font-size: 1rem;
  }
}

.webkit-redactor-content h1 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content h1{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h1{
    font-size: 1.688rem;
  }
}

.webkit-redactor-content h1{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content h2 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-redactor-content h2{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h2{
    font-size: 1.25rem;
  }
}

.webkit-redactor-content h2{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content h3 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content h3{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h3{
    font-size: 1rem;
  }
}

.webkit-redactor-content h3{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content h4 {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content h4{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h4{
    font-size: 1rem;
  }
}

.webkit-redactor-content h4{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content h5 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-redactor-content h5{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content h5{
    font-size: .7rem;
  }
}

.webkit-redactor-content h5{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content p {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content p{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content p{
    font-size: 1rem;
  }
}

.webkit-redactor-content p{
  font-weight: 300;
}

.webkit-redactor-content em {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content em{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content em{
    font-size: 1rem;
  }
}

.webkit-redactor-content strong {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content strong{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content strong{
    font-size: 1rem;
  }
}

.webkit-redactor-content strong{
  font-weight: 700;
  display: inline-block;
}

.webkit-redactor-content p:first-child strong {
  padding-top: 0px;
}

.webkit-redactor-content ul, .webkit-redactor-content ol{
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content ul, .webkit-redactor-content ol{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content ul, .webkit-redactor-content ol{
    font-size: 1rem;
  }
}

.webkit-redactor-content a {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  display: inline-block;
  font-weight: 700;
}

.webkit-redactor-content a:after {
  content: "";
  border-bottom-width: 2px;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.webkit-redactor-content p + p, .webkit-redactor-content p + ul, .webkit-redactor-content p + ol, .webkit-redactor-content ul + ol, .webkit-redactor-content ol + ul, .webkit-redactor-content ol + p, .webkit-redactor-content ul + p{
  margin-top: 1.25rem;
}

.webkit-redactor-content ul {
  list-style: none;
}

.webkit-redactor-content ul > li {
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: 0;
  padding-left: 1rem;
  text-indent: -0.5rem;
}

.webkit-redactor-content ul > li:before {
  content: "";
  transform: translateX(-100%) translateY(-30%);
  display: inline-block;
  vertical-align: baseline;
  height: 0.5rem;
  width: 0.5rem;
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 2px;
}

.webkit-redactor-content ul > li > a {
  text-indent: 0;
}

.webkit-redactor-content ol {
  list-style: decimal;
  padding-left: 1rem;
}

.webkit-redactor-content strong > em {
  font-weight: 700;
}

.webkit-redactor-content-r {
  font-size: .9375rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-redactor-content-r h1 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r h1{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r h1{
    font-size: 1.688rem;
  }
}

.webkit-redactor-content-r h1{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content-r h2 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r h2{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r h2{
    font-size: 1.25rem;
  }
}

.webkit-redactor-content-r h2{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content-r h3 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r h3{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r h3{
    font-size: 1rem;
  }
}

.webkit-redactor-content-r h3{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content-r h4 {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r h4{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r h4{
    font-size: 1rem;
  }
}

.webkit-redactor-content-r h4{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content-r h5 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r h5{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r h5{
    font-size: .7rem;
  }
}

.webkit-redactor-content-r h5{
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.webkit-redactor-content-r p {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r p{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r p{
    font-size: 1rem;
  }
}

.webkit-redactor-content-r p{
  font-weight: 300;
}

.webkit-redactor-content-r em {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r em{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r em{
    font-size: 1rem;
  }
}

.webkit-redactor-content-r strong {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r strong{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r strong{
    font-size: 1rem;
  }
}

.webkit-redactor-content-r strong{
  font-weight: 700;
  display: inline-block;
}

.webkit-redactor-content-r p:first-child strong {
  padding-top: 0px;
}

.webkit-redactor-content-r ul, .webkit-redactor-content-r ol{
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-redactor-content-r ul, .webkit-redactor-content-r ol{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-redactor-content-r ul, .webkit-redactor-content-r ol{
    font-size: 1rem;
  }
}

.webkit-redactor-content-r a {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  display: inline-block;
}

.webkit-redactor-content-r a:after {
  content: "";
  border-bottom-width: 2px;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.webkit-redactor-content-r p + p, .webkit-redactor-content-r p + ul, .webkit-redactor-content-r p + ol, .webkit-redactor-content-r ul + ol, .webkit-redactor-content-r ol + ul, .webkit-redactor-content-r ol + p, .webkit-redactor-content-r ul + p{
  margin-top: 1.25rem;
}

.webkit-redactor-content-r ul {
  list-style: none;
}

.webkit-redactor-content-r ul > li {
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: 0;
  padding-left: 1rem;
  text-indent: -0.5rem;
}

.webkit-redactor-content-r ul > li:before {
  content: "";
  transform: translateX(-100%);
  display: inline-block;
  vertical-align: baseline;
  height: 0.5rem;
  width: 0.5rem;
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 2px;
}

.webkit-redactor-content-r ul > li > a {
  text-indent: 0;
}

.webkit-redactor-content-r ol {
  list-style: decimal;
  padding-left: 1rem;
}

.webkit-redactor-content-r strong > em {
  font-weight: 700;
}

.webkit-double-list-link > .webkit-redactor-content-r > a {
  font-weight: 300;
}

a, div.webkit-menu-icon, .webkit-contact-banner-links > a {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.webkit-doubleGrid-image, .webkit-grid-image, .webkit-quad-block-image, .webkit-testimonial-image, .webkit-gallery-image{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.webkit-doubleGrid-image,
.webkit-grid-image,
.webkit-quad-block-image,
.webkit-testimonial-image,
.webkit-gallery-image{
  transition-duration: 400ms;
}

@media (min-width: 994px) {
  a[href^=https]:hover .webkit-doubleGrid-image, a[href^=https]:hover .webkit-grid-image, a[href^=https]:hover .webkit-quad-block-image, a[href^=https]:hover .webkit-testimonial-image, a[href^=https]:hover .webkit-gallery-image, a[href^=http]:hover .webkit-doubleGrid-image, a[href^=http]:hover .webkit-grid-image, a[href^=http]:hover .webkit-quad-block-image, a[href^=http]:hover .webkit-testimonial-image, a[href^=http]:hover .webkit-gallery-image, .webkit-akkordeon-entry:hover .webkit-doubleGrid-image, .webkit-akkordeon-entry:hover .webkit-grid-image, .webkit-akkordeon-entry:hover .webkit-quad-block-image, .webkit-akkordeon-entry:hover .webkit-testimonial-image, .webkit-akkordeon-entry:hover .webkit-gallery-image{
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  a[href^=https]:hover .webkit-btn__big, a[href^=https]:hover .webkit-quad-block-button, a[href^=http]:hover .webkit-btn__big, a[href^=http]:hover .webkit-quad-block-button, .webkit-akkordeon-entry:hover .webkit-btn__big, .webkit-akkordeon-entry:hover .webkit-quad-block-button{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  a[href^=https]:hover .webkit-btn__small, a[href^=http]:hover .webkit-btn__small, .webkit-akkordeon-entry:hover .webkit-btn__small{
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

@media (min-width: 994px) {
  .scroll-button-container:hover .webkit-btn__small {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .scroll-button-container:hover .webkit-btn__small-r {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.webkit-arrow-down:before, .webkit-plus:before,
.webkit-angleDown:before, .webkit-minus:before,
.webkit-angleUp:before, .webkit-arrow-right:before, .webkit-play-button:before {
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

i:before {
  vertical-align: middle;
}

.webkit-play-button:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f144";
}

.webkit-arrow-right:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 500;
  content: "\f178";
  line-height: 0.5;
  margin-top: 1px;
  font-size: 1rem;
}

@media (max-width: 993px){
  .webkit-arrow-right:before{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-arrow-right:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (max-width: 480px) {
  .webkit-arrow-right:before {
    margin-bottom: 0px;
  }
}

.webkit-minus:before, .webkit-angleUp:before{
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f106";
}

.webkit-minus:before,
.webkit-angleUp:before{
  font-size: 1rem;
  line-height: 0.5;
  margin-bottom: 1px;
}

@media (max-width: 993px){
  .webkit-minus:before, .webkit-angleUp:before{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-minus:before, .webkit-angleUp:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.webkit-plus:before, .webkit-angleDown:before{
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f107";
}

.webkit-plus:before,
.webkit-angleDown:before{
  font-size: 1rem;
  line-height: 0.5;
  margin-right: 1px;
  margin-top: 3px;
}

@media (max-width: 993px){
  .webkit-plus:before, .webkit-angleDown:before{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-plus:before, .webkit-angleDown:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.webkit-angleDown:before, .webkit-angleUp:before{
  margin: 0px;
}

.webkit-arrow-down:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  content: "\f175";
  line-height: 0.5;
  margin-bottom: 1px;
  font-size: 0.775rem;
}

@media (max-width: 480px){
  .webkit-arrow-down:before{
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (max-width: 480px) {
  .webkit-arrow-down:before {
    margin-bottom: 0px;
  }
}

.webkit-loadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.webkit-loadingSpinner > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-loadingSpinner > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-loadingSpinner > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-loadingSpinner:before {
  animation: spin 1s linear infinite;
}

.webkit-btn__small-r, .webkit-btn__small, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-header-button, .webkit-btn {
  cursor: pointer;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 400ms;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-btn__small-r, .webkit-btn__small, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-header-button, .webkit-btn{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-r, .webkit-btn__small, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-header-button, .webkit-btn{
    font-size: 1rem;
  }
}

.webkit-btn__small-r, .webkit-btn__small, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-header-button, .webkit-btn{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-btn__big, .webkit-header-button {
  display: block;
  overflow: hidden;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 480px){
  .webkit-btn__big, .webkit-header-button{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.webkit-btn__big, .webkit-header-button{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-radius: 0px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__big, .webkit-header-button{
    border-width: 2px;
  }
}

.webkit-btn__big, .webkit-header-button{
  border-width: 3px;
}

.webkit-btn__big-r {
  display: block;
  overflow: hidden;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

@media (max-width: 480px){
  .webkit-btn__big-r{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.webkit-btn__big-r{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 0px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__big-r{
    border-width: 2px;
  }
}

.webkit-btn__big-r{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-width: 3px;
}

.webkit-btn__small-filled {
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small-filled{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-filled{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small-filled > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small-filled > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-filled > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small-filled{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  cursor: default;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small-filled{
    border-width: 2px;
  }
}

.webkit-btn__small-filled{
  border-width: 1px;
}

.webkit-btn__small-filled-r {
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small-filled-r{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-filled-r{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small-filled-r > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small-filled-r > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-filled-r > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small-filled-r{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  cursor: default;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small-filled-r{
    border-width: 2px;
  }
}

.webkit-btn__small-filled-r{
  border-width: 1px;
}

.webkit-btn__small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 3px;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small{
    border-width: 2px;
  }
}

.webkit-btn__small{
  padding: 1rem;
  border-style: none;
}

.webkit-btn__small-r {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small-r{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-r{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small-r > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small-r > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-r > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small-r{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-width: 3px;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small-r{
    border-width: 2px;
  }
}

.webkit-btn__small-r{
  padding: 1rem;
  border-width: 1px;
}

@media (max-width: 768px) {
  .webkit-btn__small-r {
    border-width: 2px;
  }
}

@media (min-width: 994px) {
  .webkit-btn__big:hover, .webkit-header-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .webkit-btn__big-r:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-r:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.webkit-doubleBlock-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-doubleBlock-height {
  height: calc(100vh);
}

.doubleBlock-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.doubleBlock-blue .webkit-double-text-title, .doubleBlock-blue .webkit-double-list-label, .doubleBlock-blue .webkit-double-text-content, .doubleBlock-blue .scroll-button-container{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.doubleBlock-blue .webkit-double-list-link {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.webkit-doubleBlock-home-align {
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

@media (max-width: 768px){
  .webkit-doubleBlock-home-align{
    align-items: flex-start;
  }
}

.webkit-doubleBlock-home-align{
  padding-top: 8.333333333333332%;
  padding-bottom: 8.333333333333332%;
}

@media (max-width: 768px){
  .webkit-doubleBlock-home-align{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-doubleBlock-home-projekte {
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

@media (max-width: 768px){
  .webkit-doubleBlock-home-projekte{
    align-items: flex-start;
  }
}

.webkit-doubleBlock-home-projekte{
  padding-top: 8.333333333333332%;
  padding-bottom: 8.333333333333332%;
}

@media (max-width: 768px){
  .webkit-doubleBlock-home-projekte{
    padding-bottom: 8.333333333333332%;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-home-projekte{
    padding-top: 0px;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-home-projekte > .webkit-doubleBlock-outer-cell > .webkit-doubleBlock-grid{
    padding-top: 0px;
  }
}

.webkit-doubleBlock-outer-grid {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  align-items: center;
}

@media (max-width: 480px){
  .webkit-doubleBlock-outer-grid{
    align-items: flex-start;
  }
}

.webkit-doubleBlock-outer-cell {
  grid-column-start: 1;
  grid-column-end: 25;
}

@media (max-width: 480px){
  .webkit-doubleBlock-outer-cell{
    grid-column-start: 3;
    grid-column-end: 23;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-outer-cell{
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-doubleBlock-outer-cell{
    padding-bottom: 2rem;
  }
}

.webkit-doubleBlock-outer-button {
  grid-column-start: 11;
  grid-column-end: 23;
  padding-left: 1.75rem;
}

@media (max-width: 768px){
  .webkit-doubleBlock-outer-button{
    padding-left: 0px;
    grid-column-start: 3;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-outer-button{
    padding-top: 4.166666666666666% !important;
  }
}

@media (max-width: 480px){
  .webkit-doubleBlock-outer-button{
    grid-column-start: 2;
  }
}

.webkit-doubleBlock-grid {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

@media (max-width: 768px){
  .webkit-doubleBlock-grid{
    padding-top: 8.333333333333332%;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-grid > .webkit-double-list:nth-child(1){
    padding-top: 0px;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-grid > .webkit-double-list:nth-child(1){
    padding-bottom: 2rem;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-grid > :nth-child(1){
    margin-top: 0px;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-grid > :nth-child(1) > :nth-child(1){
    padding-top: 0px;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock-grid > :nth-child(2) > :nth-child(3){
    padding-bottom: 0px;
  }
}

@media (max-width: 768px){
  .webkit-doubleBlock{
    grid-row: auto;
  }
}

.webkit-double-image-container + * .webkit-double-text-grid {
  display: flex;
}

.webkit-double-image-container + * .webkit-double-text-grid > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-double-image-container + * .webkit-double-text-grid > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-double-image-container + * .webkit-double-text-grid > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-double-heading {
  grid-column-start: 5;
  grid-column-end: 21;
}

@media (max-width: 768px){
  .webkit-double-heading{
    grid-column-start: 3;
    grid-column-end: 23;
  }
}

.webkit-double-heading{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-double-heading{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-double-heading{
    font-size: 1.25rem;
  }
}

.webkit-double-heading{
  padding-bottom: 4.166666666666666%;
}

@media (max-width: 768px){
  .webkit-double-heading{
    padding-bottom: 0px;
  }
}

.bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img, .webkit-grid-image, .webkit-double-video-thumbnail-aspect {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.webkit-double-image-container:nth-child(1) {
  align-self: center;
  padding: 0px;
}

@media (max-width: 768px){
  .webkit-double-image-container:nth-child(1){
    order: -9999;
  }
}

.webkit-double-image-container:nth-child(1){
  grid-column-start: 1;
  grid-column-end: 11;
}

@media (max-width: 768px){
  .webkit-double-image-container:nth-child(1){
    grid-column-start: 1;
    grid-column-end: 22;
  }
}

.webkit-double-image-container:nth-child(2) {
  align-self: center;
  padding: 0px;
}

@media (max-width: 768px){
  .webkit-double-image-container:nth-child(2){
    order: -9999;
  }
}

.webkit-double-image-container:nth-child(2){
  grid-column-start: 13;
  grid-column-end: 22;
}

@media (max-width: 768px){
  .webkit-double-image-container:nth-child(2){
    grid-column-start: 1;
  }
}

@media (max-width: 768px){
  .webkit-double-image-container:nth-child(2){
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

.webkit-double-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.webkit-double-image-aspect {
  padding-top: 62.5%;
}

.webkit-double-list {
  align-self: flex-start;
}

@media (max-width: 768px){
  .webkit-double-list{
    order: 9999;
  }
}

@media (max-width: 1280px){
  .webkit-double-list{
    padding-left: 0px;
    padding-right: 0px;
  }
}

.webkit-double-list:nth-child(1) {
  grid-column-start: 2;
  grid-column-end: 10;
}

@media (max-width: 768px){
  .webkit-double-list:nth-child(1){
    grid-column-start: 2;
    grid-column-end: 20;
  }
}

.webkit-double-list:nth-child(2) {
  grid-column-start: 12;
  grid-column-end: 20;
}

@media (max-width: 768px){
  .webkit-double-list:nth-child(2){
    grid-column-start: 2;
    grid-column-end: 20;
  }
}

.webkit-double-list-link {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  gap: 1rem;
}

@media (max-width: 993px){
  .webkit-double-list-link{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

@media (max-width: 768px){
  .webkit-double-list-link{
    padding-left: 0px;
    padding-right: 0px;
  }
}

.webkit-double-list-link{
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  flex-direction: row-reverse;
}

.webkit-double-list-link .webkit-border-blue {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.webkit-double-list-link.webkit-border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  border-bottom-width: 2px;
}

.webkit-double-list-link:first-child {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

@media (max-width: 768px){
  .webkit-double-list-link:first-child{
    border-style: none;
  }
}

.webkit-double-list-link + .webkit-double-list-link {
  border-bottom-width: 1px;
}

@media (max-width: 768px) {
  .webkit-double-list-link + .webkit-double-list-link {
    padding-top: 1rem;
  }

  @media (max-width: 768px){
    .webkit-double-list-link + .webkit-double-list-link{
      border-style: none;
    }
  }
}

.webkit-double-list-label {
  align-self: center;
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-double-list-label{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-double-list-label{
    font-size: 1rem;
  }
}

.webkit-double-list-label{
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-double-list-button {
  align-self: center;
  order: -9999;
}

.webkit-doubleBlock-buttons {
  min-width: 240px;
}

.webkit-buttons-container {
  display: flex;
}

.webkit-buttons-container > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-buttons-container > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-buttons-container > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-buttons-container{
  justify-content: space-between;
  padding-top: 4rem;
}

@media (max-width: 768px){
  .webkit-buttons-container{
    flex-direction: column;
  }
}

@media (max-width: 768px){
  .webkit-buttons-container{
    gap: 1.25rem;
  }
}

@media (max-width: 768px){
  .webkit-buttons-container{
    padding-top: 8.333333333333332%;
  }
}

.webkit-buttons-icon {
  height: 17px;
}

.webkit-double-text {
  align-self: center;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 7;
  position: relative;
  display: flex;
}

.webkit-double-text > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-double-text > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-double-text > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-double-text{
  align-items: center;
}

@media (max-width: 768px) {
  .webkit-double-text {
    order: -1;
  }
}

.webkit-double-text:nth-child(1) {
  grid-column-start: 2;
  grid-column-end: 10;
}

@media (max-width: 768px){
  .webkit-double-text:nth-child(1){
    grid-column-end: 21;
  }
}

@media (max-width: 768px){
  .webkit-double-text:nth-child(1){
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.webkit-double-text:nth-child(2) {
  grid-column-start: 12;
  grid-column-end: 20;
}

@media (max-width: 768px){
  .webkit-double-text:nth-child(2){
    grid-column-start: 1;
    grid-column-end: 21;
  }
}

@media (max-width: 768px){
  .webkit-double-text:nth-child(2){
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.webkit-double-text-title {
  align-self: center;
  text-align: left;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-double-text-title{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-double-text-title{
    font-size: 1.25rem;
  }
}

@media (max-width: 768px){
  .webkit-double-text-title-ueber-mich{
    text-align: center;
  }
}

.webkit-double-text-title-ueber-mich{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-double-text-title-ueber-mich{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-double-text-title-ueber-mich{
    font-size: 1.25rem;
  }
}

.webkit-double-text-title-ueber-mich{
  margin-top: 0.5rem;
}

.webkit-double-text-content {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-double-text-content{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-double-text-content{
    font-size: 1rem;
  }
}

.webkit-double-text-content{
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-double-text-btn {
  align-self: flex-end;
  justify-self: start;
  margin-top: 12.5%;
}

@media (max-width: 993px){
  .webkit-double-text-btn{
    margin-top: 8.333333333333332%;
  }
}

@media (max-width: 768px){
  .webkit-double-text-btn{
    margin-top: 1rem;
  }
}

.webkit-double-video-container {
  align-self: center;
  overflow: hidden;
}

@media (max-width: 768px){
  .webkit-double-video-container{
    order: -9999;
  }
}

.webkit-double-video-container{
  height: 100%;
}

@media (max-width: 768px){
  .webkit-double-video-container{
    height: auto;
  }
}

.webkit-double-video {
  position: absolute;
  width: 1280px;
  height: 720px;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

@media (max-width: 1600px) {
  .webkit-double-video {
    width: 1200px;
    height: 675px;
  }
}

@media (max-width: 1200px) {
  .webkit-double-video {
    height: 100%;
    width: 100%;
  }
}

.webkit-double-video-thumbnail {
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.webkit-double-video-play {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}

.webkit-double-video-play-button {
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
}

.webkit-double-video-play-text {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-double-video-play-text{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-double-video-play-text{
    font-size: .7rem;
  }
}

.webkit-double-video-play-text{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-double-video-thumbnail-aspect {
  height: 100%;
  padding-top: 75%;
}

@media (max-width: 768px){
  .webkit-double-video-thumbnail-aspect{
    padding-top: 56.25%;
  }
}

.webkit-double-video-overlay-container {
  position: absolute;
  max-width: 1600px;
}

@media (max-width: 1200px){
  .webkit-double-video-overlay-container{
    height: 0px;
    padding-top: 56.25%;
  }
}

.webkit-double-video-overlay-container{
  top: 50%;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
  width: 100%;
}

.webkit-double-video-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  cursor: pointer;
  overflow: hidden;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.webkit-double-video-overlay-close {
  position: absolute;
  top: -20rem;
  right: -1rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 1600px) {
  .webkit-double-video-overlay-close {
    top: -20rem;
    right: 0;
  }
}

@media (max-width: 1200px) {
  .webkit-double-video-overlay-close {
    top: -12%;
    right: 10px;
  }
}

@media (max-width: 768px) {
  .webkit-double-video-overlay-close {
    top: -14%;
  }
}

.webkit-akkordeon-container {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  padding-top: 8.333333333333332%;
  padding-bottom: 8.333333333333332%;
}

@media (max-width: 1200px){
  .webkit-akkordeon-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 8.333333333333332%;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-akkordeon {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-akkordeon-heading {
  padding-bottom: 8.333333333333332%;
  grid-column-start: 5;
  grid-column-end: 16;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-akkordeon-heading{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-heading{
    font-size: 1.25rem;
  }
}

.webkit-akkordeon-heading{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 993px) {
  .webkit-akkordeon-heading {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-akkordeon-heading {
    padding-bottom: 1.25rem;
  }
}

.webkit-akkordeon-entry {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.webkit-akkordeon-entry > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-akkordeon-entry > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-akkordeon-entry > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-akkordeon-entry{
  justify-content: space-between;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-width: 2px;
  margin-bottom: 0.75rem;
}

.webkit-akkordeon-entry:last-child {
  margin-bottom: 0px;
}

.akkordeon-content a:first-child {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
}

.webkit-akkordeon-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 768px){
  .webkit-akkordeon-title{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-title{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.webkit-akkordeon-title{
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  width: 100%;
}

.webkit-akkordeon-title-text {
  align-self: center;
  padding-right: 0.5rem;
  grid-column-start: 2;
  grid-column-end: 17;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-akkordeon-title-text{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-title-text{
    font-size: 1rem;
  }
}

.webkit-akkordeon-title-text{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 700;
}

.webkit-akkordeon-text-container {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
}

.webkit-akkordeon-text.webkit-redactor-content {
  padding-bottom: 2rem;
  grid-column-start: 2;
  grid-column-end: 18;
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-akkordeon-text.webkit-redactor-content{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-akkordeon-text.webkit-redactor-content{
    font-size: 1rem;
  }
}

.webkit-akkordeon-text.webkit-redactor-content{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-akkordeon-block {
  grid-column-start: 5;
  grid-column-end: 19;
}

@media (max-width: 768px){
  .webkit-akkordeon-block{
    grid-column-start: 3;
    grid-column-end: 23;
  }
}

.webkit-akkordeon-icon-container {
  align-self: center;
  justify-self: end;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 3px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 30px;
}

@media (max-width: 993px) {
  .webkit-akkordeon-active {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }

  .webkit-akkordeon-icon-container .webkit-btn__small {
    transition-property: none;
  }
}

.webkit-contact-banner-container {
  display: grid;
  z-index: 10;
  grid-template-columns: repeat(24, 1fr);
  border-top: 1px solid;
}

.webkit-contact-banner-outer-cell {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
}

.webkit-contact-banner-bg-img {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  padding-top: 33.33333333333333%;
}

.webkit-contact-banner-grid {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  display: grid;
  height: 100%;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-contact-banner-buttons {
  align-self: center;
  grid-column-start: 10;
  grid-column-end: 20;
}

@media (max-width: 1200px){
  .webkit-contact-banner-buttons{
    grid-column-start: 11;
    grid-column-end: 18;
  }
}

@media (max-width: 993px){
  .webkit-contact-banner-buttons{
    grid-column-start: 2;
    grid-column-end: 19;
  }
}

@media (max-width: 768px){
  .webkit-contact-banner-buttons{
    grid-column: span 22 / span 22;
    grid-column-start: 2;
  }
}

.webkit-contact-banner {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 480px){
  .webkit-contact-banner{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.webkit-contact-banner{
  display: flex;
}

.webkit-contact-banner > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-contact-banner > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-contact-banner > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-contact-banner{
  justify-content: flex-end;
}

.webkit-contact-banner-text {
  align-self: center;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-contact-banner-text{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-text{
    font-size: 1.25rem;
  }
}

@media (max-width: 1200px) {
  .webkit-contact-banner-text {
    display: flex;
    justify-content: flex-start;
  }

  .webkit-contact-banner-text > a.webkit-menu-icon {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
  }

  @media (min-width: 994px){
    .webkit-contact-banner-text > a.webkit-menu-icon:hover {
      --tw-border-opacity: 1;
      border-color: rgba(0, 0, 0, var(--tw-border-opacity));
      border-width: 2px;
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(0, 0, 0, var(--tw-text-opacity));
    }

    .webkit-contact-banner-text > div.webkit-menu-icon:hover {
      --tw-bg-opacity: 1;
      background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
    }
  }

  @media (max-width: 993px){
    .webkit-contact-banner-text{
      justify-content: center;
    }
  }

  .webkit-contact-banner-text{
    margin-bottom: 0.75rem;
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0px;
  }
}

.webkit-contact-banner-heading {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-contact-banner-heading{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-heading{
    font-size: 1rem;
  }
}

.webkit-contact-banner-actions {
  align-self: flex-end;
  display: flex;
}

.webkit-contact-banner-actions > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-contact-banner-actions > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-contact-banner-actions > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

@media (max-width: 1200px) {
  .webkit-contact-banner-actions {
    flex-basis: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .webkit-contact-banner-actions > a.webkit-menu-icon {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
  }

  @media (min-width: 994px){
    .webkit-contact-banner-actions > a.webkit-menu-icon:hover {
      --tw-border-opacity: 1;
      border-color: rgba(0, 0, 0, var(--tw-border-opacity));
      border-width: 2px;
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(0, 0, 0, var(--tw-text-opacity));
    }

    .webkit-contact-banner-actions > div.webkit-menu-icon:hover {
      --tw-bg-opacity: 1;
      background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
    }
  }
}

@media (max-width: 768px) {
  .webkit-contact-banner-actions {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .webkit-contact-banner-actions {
    display: flex;
  }

  .webkit-contact-banner-actions > a.webkit-menu-icon {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
  }

  @media (min-width: 994px){
    .webkit-contact-banner-actions > a.webkit-menu-icon:hover {
      --tw-border-opacity: 1;
      border-color: rgba(0, 0, 0, var(--tw-border-opacity));
      border-width: 2px;
      --tw-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(0, 0, 0, var(--tw-text-opacity));
    }

    .webkit-contact-banner-actions > div.webkit-menu-icon:hover {
      --tw-bg-opacity: 1;
      background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
      --tw-text-opacity: 1;
      color: rgba(255, 255, 255, var(--tw-text-opacity));
    }
  }

  .webkit-contact-banner-actions{
    flex-wrap: wrap;
  }
}

.webkit-contact-banner-subtext {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-contact-banner-subtext{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-subtext{
    font-size: 1rem;
  }
}

.webkit-contact-banner-subtext{
  max-width: 40%;
}

@media (max-width: 1200px) {
  .webkit-contact-banner-subtext {
    text-align: center;
    max-width: 60%;
  }
}

@media (max-width: 993px) {
  .webkit-contact-banner-subtext {
    max-width: 30%;
  }
}

@media (max-width: 768px) {
  .webkit-contact-banner-subtext {
    max-width: 35%;
  }
}

@media (max-width: 480px) {
  .webkit-contact-banner-subtext {
    max-width: 42%;
  }
}

.webkit-contact-banner-button {
  flex: none;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (max-width: 993px){
  .webkit-contact-banner-button{
    text-align: center;
  }
}

.webkit-contact-banner-button{
  margin-bottom: 1rem;
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-contact-banner-button{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-button{
    font-size: 1rem;
  }
}

.webkit-contact-banner-button{
  font-weight: 700;
}

.webkit-contact-banner-button:first-child {
  margin-right: 0.75rem;
}

@media (max-width: 480px){
  .webkit-contact-banner-button:first-child{
    margin-right: 0.5rem;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-button:first-child{
    margin-bottom: 0px;
  }
}

@media (max-width: 480px){
  .webkit-contact-banner-button:last-child{
    margin-bottom: 0px;
  }
}

.button-color-without-img {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

@media (min-width: 994px){
  .button-color-without-img:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }
}

.button-color-with-img {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (min-width: 994px){
  .button-color-with-img:hover{
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.webkit-contact-banner-links {
  display: flex;
  flex-direction: column;
}

.webkit-contact-banner-links > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-contact-banner-links > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-contact-banner-links > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-contact-banner-links{
  justify-content: center;
  grid-column-start: 20;
  grid-column-end: 23;
}

@media (max-width: 768px){
  .webkit-contact-banner-links{
    display: none;
  }
}

.webkit-contact-banner-links > a {
  text-align: right;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  text-decoration: underline;
  text-decoration-color: black;
}

@media (min-width: 994px) {
  .webkit-contact-banner-links > a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}

@media (max-width: 768px){
  .webkit-contact-banner-button-value{
    display: none;
  }
}

@media (min-width: 769px){
  .webkit-contact-banner-button-text{
    display: none;
  }
}

.webkit-heading-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-heading {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-bottom: 0px;
  padding-top: 8.333333333333332%;
}

@media (max-width: 768px){
  .webkit-heading{
    padding-top: 12.5%;
  }
}

.webkit-heading{
  text-align: left;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-heading{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-heading{
    font-size: 1.688rem;
  }
}

.webkit-heading{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-heading-cell {
  grid-column-start: 5;
  grid-column-end: 21;
}

@media (max-width: 768px){
  .webkit-heading-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-imageBanner {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  padding-top: calc(100vh - 200px);
}

@media (max-width: 1200px) {
  .webkit-imageBanner {
    padding-top: calc(100vh - 167px - 45px );
  }
}

@media (max-width: 993px) {
  .webkit-imageBanner {
    padding-top: calc(100vh - 200.43px - 45px);
  }
}

@media (max-width: 768px) {
  .webkit-imageBanner {
    padding-top: calc(100vh - 198px - 45px);
  }
}

@media (max-width: 480px) {
  .webkit-imageBanner {
    padding-top: calc(100vh - 293.5px - 45px );
  }
}

.webkit-imageBanner-grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-imageBanner-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.webkit-text-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 4.166666666666666%;
  padding-bottom: 8.333333333333332%;
}

@media (max-width: 1200px){
  .webkit-text-container{
    padding-bottom: 8.333333333333332%;
  }
}

@media (max-width: 993px){
  .webkit-text-container{
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 768px){
  .webkit-text-container{
    padding-top: 8.333333333333332%;
    padding-bottom: 8.333333333333332%;
  }
}

.webkit-text {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  text-align: left;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-text{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-text{
    font-size: .7rem;
  }
}

.webkit-text{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-text-heading {
  grid-column-start: 5;
  grid-column-end: 21;
}

@media (max-width: 768px){
  .webkit-text-heading{
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-text-heading{
    grid-column-end: 23;
  }
}

.webkit-text-heading{
  padding-bottom: 4rem;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-text-heading{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-text-heading{
    font-size: 1.688rem;
  }
}

@media (max-width: 993px) {
  .webkit-text-heading {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-text-heading {
    padding-bottom: 1.25rem;
  }
}

.webkit-text-cell {
  grid-column-start: 5;
  grid-column-end: 19;
}

@media (max-width: 768px){
  .webkit-text-cell{
    grid-column-start: 3;
  }
}

@media (max-width: 480px){
  .webkit-text-cell{
    grid-column-end: 23;
  }
}

.webkit-zeitstrahl-container {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 4.166666666666666%;
  padding-bottom: 4.166666666666666%;
}

.webkit-zeitstrahl-cell {
  grid-column-start: 3;
  grid-column-end: 22;
}

@media (max-width: 480px){
  .webkit-zeitstrahl-cell{
    grid-column-start: 2;
    grid-column-end: 24;
  }
}

.webkit-zeitstrahl-cell:last-child {
  grid-gap: 1rem;
  gap: 1rem;
}

.webkit-zeitstahl-grid {
  display: grid;
  column-gap: 3rem;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
}

.webkit-zeitstahl-grid:last-child {
  display: flex;
}

.webkit-zeitstahl-grid:last-child > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-zeitstahl-grid:last-child > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-zeitstahl-grid:last-child > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-zeitstahl-grid:last-child{
  gap: 3rem;
}

.webkit-zeitstahl-grid:last-child .webkit-zeitstrahl-year {
  max-width: 68px;
}

.webkit-zeitstahl-grid:last-child .webkit-zeitstahl-line {
  display: none;
}

@media (max-width: 993px) {
  .webkit-zeitstahl-grid:last-child {
    gap: 1.5rem;
  }
}

.webkit-zeitstrahl-year {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-zeitstrahl-year{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-zeitstrahl-year{
    font-size: 1rem;
  }
}

.webkit-zeitstrahl-year{
  align-self: center;
  justify-self: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.webkit-zeitstahl-text {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.4375rem;
}

@media (max-width: 993px){
  .webkit-zeitstahl-text{
    font-size: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-zeitstahl-text{
    font-size: .8rem;
  }
}

.webkit-zeitstahl-text{
  align-self: center;
}

.webkit-zeitstahl-line {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.webkit-zeitstahl-line:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(179, 187, 195, var(--tw-bg-opacity));
  border-radius: 9999px;
  display: block;
  height: 5rem;
  margin-left: auto;
  margin-right: auto;
  width: 1px;
}

.webkit-zeitstrahl-connect {
  position: relative;
}

.webkit-zeitstrahl-connect:after {
  content: "–";
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-zeitstrahl-connect:after{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-zeitstrahl-connect:after{
    font-size: 1rem;
  }
}

.webkit-zeitstrahl-connect:after{
  position: absolute;
  top: 0px;
  right: -1.25rem;
}

@media (max-width: 768px){
  .webkit-zeitstrahl-connect:after{
    right: -1rem;
  }
}

.webkit-zeitstrahl-connect:after{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-linkbutton {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.webkit-linkbutton-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.webkit-linkbutton-inner-container {
  grid-column-start: 4;
  grid-column-end: 22;
  display: flex;
}

.webkit-linkbutton-inner-container > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-linkbutton-inner-container > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-linkbutton-inner-container > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-linkbutton-inner-container{
  justify-content: space-evenly;
}

@media (max-width: 993px){
  .webkit-linkbutton-inner-container{
    flex-direction: column;
  }
}

@media (max-width: 768px){
  .webkit-linkbutton-inner-container{
    gap: 1rem;
  }
}

.webkit-linkbutton-text-container {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  min-width: 240px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.webkit-linkbutton-text-container > a.webkit-menu-icon {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  border-width: 2px !important;
}

@media (min-width: 994px){
  .webkit-linkbutton-text-container > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
    border-width: 2px !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .webkit-linkbutton-text-container > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }
}

.webkit-grid-button, .webkit-grid-text, .webkit-grid-heading, .webkit-grid-heading2 {
  justify-content: flex-start;
  text-align: left;
}

.webkit-grid-title {
  text-align: left;
  grid-column-start: 5;
  grid-column-end: 21;
}

@media (max-width: 768px){
  .webkit-grid-title{
    grid-column-start: 3;
    grid-column-end: 23;
  }
}

.webkit-grid-title{
  padding-bottom: 4.166666666666666%;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-grid-title{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-title{
    font-size: 1.25rem;
  }
}

@media (max-width: 993px) {
  .webkit-grid-title {
    padding-bottom: 1.65rem;
  }
}

@media (max-width: 480px) {
  .webkit-grid-title {
    padding-bottom: 1.25rem;
  }
}

.webkit-grid-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-top: 8.333333333333332%;
  padding-bottom: 8.333333333333332%;
}

@media (max-width: 1200px){
  .webkit-grid-container{
    padding-top: 4.166666666666666%;
    padding-bottom: 4.166666666666666%;
  }
}

@media (max-width: 768px){
  .webkit-grid-container{
    padding-top: 12.5%;
    padding-bottom: 12.5%;
  }
}

.webkit-grid-container-cell {
  grid-column: span 22 / span 22;
  grid-column-start: 2;
  background-image: none;
}

.webkit-grid-parent {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
  gap: 3rem;
}

@media (max-width: 993px){
  .webkit-grid-parent{
    gap: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-parent{
    gap: 0.75rem;
  }
}

.webkit-grid-parent{
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1600px){
  .webkit-grid-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1200px){
  .webkit-grid-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 993px){
  .webkit-grid-parent{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px){
  .webkit-grid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px){
  .webkit-grid-parent{
    grid-template-columns: repeat(1, 1fr);
  }
}

.webkit-grid-item {
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  position: relative;
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 2px;
}

@media (min-width: 994px) {
  .webkit-grid-item:hover > .webkit-grid-item-grid > .webkit-grid-button-cell > .webkit-grid-button > .webkit-btn__big {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-grid-item-grid {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content max-content max-content max-content 1fr max-content;
}

.webkit-grid-item-grid > * {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
}

.webkit-grid-cell-background {
  grid-row: span 6 / span 6;
  grid-row-start: 1;
  background-color: transparent;
}

.webkit-grid-cell-img {
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  overflow: hidden;
}

.webkit-grid-image {
  width: 100%;
  padding-top: 54.166666666666664%;
}

@media (max-width: 993px){
  .webkit-grid-image{
    padding-top: 75%;
  }
}

@media (max-width: 480px){
  .webkit-grid-image{
    padding-top: 66.66666666666666%;
  }
}

.webkit-grid-spacing-above-text {
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  padding-bottom: 1rem;
}

.webkit-grid-cell-heading2 {
  padding-bottom: 0.25rem;
  grid-row: span 1 / span 1;
  grid-row-start: 3;
}

.webkit-grid-heading2 {
  padding-left: 4.166666666666666%;
  padding-right: 4.166666666666666%;
}

@media (max-width: 768px){
  .webkit-grid-heading2{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.webkit-grid-heading2{
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-grid-heading2{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-heading2{
    font-size: 1rem;
  }
}

.webkit-grid-heading2{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-grid-heading {
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  padding-left: 4.166666666666666%;
  padding-right: 4.166666666666666%;
  padding-bottom: 1rem;
}

@media (max-width: 768px){
  .webkit-grid-heading{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.webkit-grid-heading{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-grid-heading{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-heading{
    font-size: 1.25rem;
  }
}

.webkit-grid-heading{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-grid-text {
  grid-row: span 1 / span 1;
  grid-row-start: 5;
  padding-left: 8.333333333333332%;
  padding-right: 8.333333333333332%;
  padding-bottom: 1rem;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-grid-text{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-grid-text{
    font-size: .7rem;
  }
}

.webkit-grid-text{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-grid-button-cell {
  grid-row: span 1 / span 1;
  grid-row-start: 6;
}

.webkit-grid-button {
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-end;
}

@media (max-width: 768px){
  .webkit-grid-button{
    justify-content: flex-end;
  }
}

.webkit-grid-button > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-grid-button > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-grid-button > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-grid-button{
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px){
  .webkit-grid-button{
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.webkit-grid-load-more-button-container {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1rem;
}

.webkit-grid-load-more-button-container > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-grid-load-more-button-container > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-grid-load-more-button-container > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-gallery-load-more-button-container {
  display: flex;
}

.webkit-gallery-load-more-button-container > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-gallery-load-more-button-container > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-gallery-load-more-button-container > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-gallery-load-more-button-container{
  justify-content: center;
  padding-top: 4rem;
}

.webkit-slideshow-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: grid;
  position: relative;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-slideshow {
  overflow: hidden;
  grid-column: span 24 / span 24;
  grid-column-start: 1;
}

.webkit-slide-text-container {
  display: grid;
  align-self: center;
  position: absolute;
  top: 0px;
  width: 100%;
  padding-left: 16.666666666666664%;
  padding-right: 16.666666666666664%;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}

@media (max-width: 768px){
  .webkit-slide-text-container{
    padding-left: 8.333333333333332%;
    padding-right: 8.333333333333332%;
  }
}

.webkit-slide-text-container{
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content max-content;
  padding-top: 41.66666666666667%;
  padding-bottom: 41.66666666666667%;
  height: auto;
  top: 100%;
  bottom: auto;
  transform: translateY(-100%);
}

.webkit-slide-button-container {
  padding-top: 4.166666666666666%;
  text-align: right;
}

.webkit-slide-button {
  display: inline-block;
}

.webkit-slide-image {
  padding-top: 33.33333333333333%;
}

@media (max-width: 993px){
  .webkit-slide-image{
    padding-top: 56.25%;
  }
}

@media (max-width: 480px){
  .webkit-slide-image{
    padding-top: 62.5%;
  }
}

.webkit-slide-image{
  background-repeat: no-repeat;
  background-size: cover;
}

.webkit-slide-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0;
}

.webkit-slide-heading {
  padding-bottom: 1.75rem;
}

@media (max-width: 993px){
  .webkit-slide-heading{
    padding-bottom: 1rem;
  }
}

.webkit-slide-heading{
  text-align: left;
  user-select: none;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-slide-heading{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-slide-heading{
    font-size: .7rem;
  }
}

.webkit-slide-heading{
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
}

@media (max-width: 480px) {
  .webkit-slide-heading {
    padding-bottom: 0.75rem;
    margin-left: 4.166666666666666%;
    margin-right: 4.166666666666666%;
  }
}

.webkit-slide-heading-single {
  align-self: flex-end;
  margin-bottom: 12.5%;
}

.webkit-slide-text {
  text-align: left;
  user-select: none;
}

@media (max-width: 768px){
  .webkit-slide-text{
    margin-left: 8.333333333333332%;
    margin-right: 8.333333333333332%;
  }
}

.webkit-slide-text{
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-slide-text{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-slide-text{
    font-size: 1rem;
  }
}

.webkit-slide-text{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (max-width: 480px){
  .webkit-slide-text{
    font-size: 1rem;
  }
}

.webkit-slide-text{
  margin-top: 1.5rem;
}

.slide {
  overflow: hidden;
  height: 100%;
  position: relative;
}

.agile__actions {
  display: block;
  text-align: center;
  width: 100%;
  height: 0;
  bottom: 0;
}

.agile__dot {
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.agile__dot button {
  display: block;
  margin: 0;
  padding: 0;
  height: 12px;
  width: 12px;
  font-size: 0;
  border-radius: 50%;
  line-height: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-width: 2px;
}

@media (max-width: 480px) {
  .agile__dot button {
    height: 10px;
    width: 10px;
  }
}

.agile__dot--current button {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-width: 2px;
}

.agile__dots {
  position: relative;
  top: -6rem;
  display: inline-block !important;
}

@media (max-width: 480px) {
  .agile__dots {
    top: -4rem;
  }
}

.agile__slide .agile__nav-button {
  display: none !important;
}

.agile__slide .agile__dots {
  display: inline-block !important;
  height: 50px;
  width: 150px;
}

.webkit-slideshow-container .agile__nav-button {
  display: none;
}

.webkit-slideshowText-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  padding-top: 4.166666666666666%;
  padding-bottom: 4.166666666666666%;
  padding-bottom: 0px;
}

@media (max-width: 768px){
  .webkit-slideshowText-container{
    margin-top: -3rem;
    padding-top: 0px;
  }
}

.webkit-slideshowText-container{
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

.webkit-slideshowText-heading {
  grid-column-start: 5;
  grid-column-end: 21;
}

@media (max-width: 768px){
  .webkit-slideshowText-heading{
    grid-column-start: 3;
    grid-column-end: 23;
  }
}

.webkit-slideshowText-heading{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-slideshowText-heading{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-slideshowText-heading{
    font-size: 1.25rem;
  }
}

.webkit-slideshowText-heading{
  padding-bottom: 4.166666666666666%;
}

@media (max-width: 768px){
  .webkit-slideshowText-heading{
    padding-bottom: 0px;
  }
}

.webkit-slideshowText-slider {
  grid-column-start: 5;
  grid-column-end: 21;
}

@media (max-width: 768px){
  .webkit-slideshowText-slider{
    grid-column-start: 1;
    grid-column-end: 25;
  }
}

@media (max-width: 480px) {
  .webkit-slideshowText-slider > .webkit-slideshow-container > .webkit-slideshow > .agile > .agile__actions > .agile__dots {
    top: -5.5rem !important;
  }
}

.webkit-slideshowText-slider > .webkit-slideshow-container > .webkit-slideshow > .agile > .agile__list > .agile__track > .agile__slides > .slide > .webkit-slide-image {
  padding-top: 66.66666666666666%;
}

@media (max-width: 768px){
  .webkit-slideshowText-slider > .webkit-slideshow-container > .webkit-slideshow > .agile > .agile__list > .agile__track > .agile__slides > .slide > .webkit-slide-image{
    padding-top: 75%;
  }
}

@media (min-width: 769px){
  .webkit-slideshowText-mobile{
    display: none;
  }
}

.webkit-slideshowText-mobile{
  padding-top: 8.333333333333332%;
}

@media (max-width: 768px){
  .webkit-slideshowText-desktop{
    display: none;
  }
}

.webkit-header {
  display: grid;
  top: 0px;
  width: 100%;
  grid-template-columns: repeat(24, 1fr);
  z-index: 9999;
  height: 5rem;
}

.webkit-header {
  position: fixed;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-bottom-width: 2px;
}

.webkit-header-logo {
  width: max-content;
  grid-column-start: 3;
  grid-column-end: 5;
}

@media (max-width: 768px){
  .webkit-header-logo{
    grid-column-start: 3;
    grid-column-end: 10;
  }
}

.webkit-header-logo{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.webkit-header-logo:first-child {
  align-items: center;
}

.webkit-header-logo-image {
  width: auto;
  height: 45px;
}

@media (max-width: 768px) {
  .webkit-header-logo-image {
    height: 45px;
  }
}

.webkit-header-button {
  justify-self: end;
  text-align: center;
  grid-column: span 2 / span 2;
  grid-column-start: 22;
  grid-column: span 2 / span 2;
  grid-column-start: 22;
}

@media (max-width: 1600px){
  .webkit-header-button{
    grid-column: span 3 / span 3;
    grid-column-start: 21;
  }
}

@media (max-width: 993px){
  .webkit-header-button{
    grid-column: span 4 / span 4;
    grid-column-start: 19;
  }
}

@media (max-width: 480px){
  .webkit-header-button{
    grid-column: span 6 / span 6;
    grid-column-start: 15;
  }
}

.webkit-header-navigation-container {
  display: none;
}

@media (max-width: 768px) {
  .webkit-header-navigation-container {
    display: block;
    align-self: center;
    justify-self: end;
    order: 9999;
    grid-column-start: 20;
    grid-column-end: 25;
    height: 45px;
  }
}

.webkit-menu-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 50;
  font-size: 1.25rem;
  height: 45px;
  width: 45px;
}

.webkit-menu-icon i {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: auto;
}

@media (min-width: 994px) {
  .webkit-header-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-header-navigation-link {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

@media (max-width: 993px){
  .webkit-header-navigation-link{
    font-size: 2.188rem;
  }
}

@media (max-width: 480px){
  .webkit-header-navigation-link{
    font-size: 1.25rem;
  }
}

.webkit-header-navigation-link{
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-header-navigation-link:after {
  content: "";
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  opacity: 0;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-bottom-width: 2px;
}

.webkit-header-navigation-link .webkit-menu-icon-phone {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (min-width: 994px) {
  .webkit-header-navigation-link .webkit-menu-icon-phone :hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

@media (min-width: 994px) {
  .webkit-header-navigation-link:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .webkit-header-navigation-link:hover:after {
    opacity: 1;
  }
}

.webkit-header-placeholder {
  pointer-events: none;
  height: 80px;
}

.webkit-language-switcher-container {
  display: none;
}

.webkit-language-switcher {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-language-switcher:hover{
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
}

.webkit-language-switcher{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-language-switcher{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-language-switcher{
    font-size: .7rem;
  }
}

@media (max-width: 768px) {
  .webkit-language-switcher {
    font-size: .625rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-language-switcher ul li {
  display: inline-block;
  vertical-align: top;
}

.webkit-language-switcher ul li > a {
  color: inherit;
}

.webkit-language-switcher ul li + li {
  margin-left: 1rem;
}

@media (max-width: 480px){
  .webkit-language-switcher ul li + li{
    margin-left: 0.5rem;
  }
}

.webkit-current-language {
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
}

.flex > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px) {
  .flex > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

@media (min-width: 994px) {
  .flex > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-header-desktop-nav {
  justify-self: end;
  padding-right: 1rem;
  grid-column: span 17 / span 17;
  grid-column-start: 5;
}

@media (max-width: 1600px){
  .webkit-header-desktop-nav{
    padding-right: 0px;
    grid-column: span 16 / span 16;
    grid-column-start: 5;
  }
}

.webkit-header-desktop-nav{
  display: inline-block;
  align-self: center;
}

.webkit-header-desktop-item {
  display: inline-block;
}

.webkit-header-desktop-item + .webkit-header-desktop-item {
  margin-left: 2rem;
}

.webkit-header-desktop-link {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-header-desktop-link:hover{
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
}

.webkit-header-desktop-link{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-header-desktop-link{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-header-desktop-link{
    font-size: .7rem;
  }
}

.webkit-header-desktop-link{
  display: inline-block;
}

.webkit-header-desktop-link:after {
  content: "";
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  opacity: 0;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
  border-bottom-width: 2px;
}

@media (min-width: 994px) {
  .webkit-header-desktop-link:hover {
    --tw-text-opacity: 1;
    color: rgba(112, 112, 112, var(--tw-text-opacity));
  }

  .webkit-header-desktop-link:hover:after {
    opacity: 1;
  }
}

.webkit-header-desktop-item-list {
  display: inline-block;
}

.webkit-header-desktop-activeLink {
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.webkit-header-desktop-activeLink:after {
  content: "";
  opacity: 1;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
  border-bottom-width: 2px;
}

.webkit-header-desktop-nav .webkit-language-switcher {
  display: inline-block;
  margin-right: 0px;
  margin-left: 2.5rem;
}

@media (max-width: 768px) {
  .webkit-header-desktop-nav {
    display: none;
  }
}

.webkit-header-nav-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 37.5%;
  right: -37.5%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  transition: right 0.2s ease, opacity 0.3s ease-out;
}

@media (max-width: 1200px) {
  .webkit-header-nav-overlay {
    width: 45%;
    right: -45%;
  }
}

@media (max-width: 993px) {
  .webkit-header-nav-overlay {
    width: 58%;
    right: -58%;
  }
}

@media (max-width: 768px) {
  .webkit-header-nav-overlay {
    width: 100%;
    right: -100%;
  }
}

@media (max-width: 480px) {
  .webkit-header-nav-overlay {
    width: 100%;
    right: -100%;
  }
}

.webkit-header-nav-overlay.overlay-active {
  z-index: 9999;
  opacity: 1;
  right: 0;
}

.webkit-header-nav-overlay-grid {
  display: grid;
  height: 100%;
  grid-template-rows: -webkit-max-content -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content max-content 1fr max-content;
}

.webkit-header-nav-overlay-header {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  height: 5rem;
  align-items: center;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-bottom-width: 2px;
}

.webkit-header-nav-overlay-image {
  grid-column-start: 3;
  grid-column-end: 10;
  height: 45px;
  background-repeat: no-repeat;
  background-size: contain;
  width: auto;
}

.webkit-header-nav-overlay-close {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  grid-column-start: 21;
  grid-column-end: 23;
  justify-self: center;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-size: 1.25rem;
}

.webkit-language-switcher-container-mobile {
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  margin-top: 2rem;
  margin-left: 5rem;
}

@media (max-width: 480px){
  .webkit-language-switcher-container-mobile{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: 3rem;
  }
}

.webkit-header-nav-item-list {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.webkit-header-nav-item-list > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-header-nav-item-list > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-header-nav-item-list > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-header-nav-item-list{
  grid-column: span 1 / span 1;
  grid-column-start: 1;
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  text-align: center;
}

.webkit-header-nav-item + .webkit-header-nav-item {
  padding-top: 1.5rem;
}

.webkit-nav-overlay-socials {
  padding-left: 3.5rem;
  width: 100%;
  grid-row: span 1 / span 1;
  grid-row-start: 4;
  align-self: center;
  justify-self: start;
  font-size: .625rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-top-width: 1px;
}

@media (max-width: 480px){
  .webkit-nav-overlay-socials{
    justify-self: start;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.webkit-header-navigation-activeLink {
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.webkit-header-navigation-activeLink:after {
  content: "";
  opacity: 1;
  display: block;
  width: 100%;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-bottom-width: 2px;
}

.webkit-nav-overlay-contact {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  vertical-align: middle;
}

.webkit-nav-overlay-contact-item {
  display: inline-block;
  padding-left: 1.5rem;
  font-size: .625rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-nav-overlay-contact-item i:before {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.webkit-nav-overlay-social-media {
  display: inline-block;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: .9375rem;
}

.webkit-nav-overlay-social-media a {
  display: inline-block;
}

.webkit-nav-overlay-social-media a + a {
  margin-left: 1rem;
}

.webkit-header-nav-overlay-buttons {
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
}

.webkit-header-nav-overlay-buttons > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-header-nav-overlay-buttons > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-header-nav-overlay-buttons > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-header-nav-overlay-buttons{
  gap: 0.5rem;
  justify-content: center;
}

.webkit-header-nav-overlay-button {
  text-align: center;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.webkit-header-nav-overlay-button:first {
  margin-bottom: 0.75rem;
}

.bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img, .webkit-intro-foreground-image, .webkit-intro-background-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.webkit-intro-container {
  overflow: hidden;
  display: grid;
  height: 100%;
  grid-template-columns: repeat(24, 1fr);
}

.webkit-intro-background-image {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
  width: 100%;
  height: auto;
  background-attachment: fixed;
  padding-top: 37.5%;
}

@media (max-width: 1600px){
  .webkit-intro-background-image{
    padding-top: 41.66666666666667%;
  }
}

@media (max-width: 768px){
  .webkit-intro-background-image{
    padding-top: 56.25%;
  }
}

.webkit-intro-text-placeholder {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}

.webkit-intro-foreground-image {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 3;
  padding-top: 33.33333333333333%;
  margin-top: -8rem;
  z-index: 9;
}

.webkit-intro-text-fixed, .webkit-intro-text-container {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-self: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-top: 12.5%;
  text-align: center;
  width: 100%;
}

@media (max-width: 993px){
  .webkit-intro-text-fixed, .webkit-intro-text-container{
    padding-top: 20.833333333333336%;
    padding-bottom: 25%;
  }
}

@media (max-width: 480px){
  .webkit-intro-text-fixed, .webkit-intro-text-container{
    padding-bottom: 20.833333333333336%;
    padding-top: 25%;
    grid-column: span 22 / span 22;
    grid-column-start: 2;
  }
}

.webkit-intro-text-container {
  visibility: hidden;
}

.webkit-intro-text-fixed {
  position: fixed;
  visibility: visible;
}

.webkit-intro-heading {
  margin-left: 4.166666666666666%;
  margin-right: 4.166666666666666%;
  margin-bottom: 1rem;
  user-select: none;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-intro-heading{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-heading{
    font-size: 1.688rem;
  }
}

.webkit-intro-heading{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-intro-text {
  top: 50%;
  margin-left: 20.833333333333336%;
  margin-right: 20.833333333333336%;
  user-select: none;
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-intro-text{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-text{
    font-size: 1rem;
  }
}

.webkit-intro-text{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img, .webkit-intro-slider-background-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.webkit-intro-slider-container {
  overflow: hidden;
  display: grid;
  height: 100%;
  grid-template-columns: repeat(24, 1fr);
}

@media (max-width: 768px){
  .webkit-intro-slider-container{
    margin-bottom: 16.666666666666664%;
  }
}

@media (max-width: 1200px){
  .webkit-intro-slider-container{
    margin-bottom: 1.5rem;
  }
}

.webkit-intro-slider-container{
  grid-template-rows: 1fr -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: 1fr max-content max-content max-content max-content max-content;
}

@media (max-width: 768px) {
  .webkit-intro-slider-container {
    height: 90vh;
  }
}

@media (max-width: 993px) {
  .webkit-intro-slider-container {
    height: 97vh;
  }
}

@media (max-width: 1200px) {
  .webkit-intro-slider-container {
    height: 96vh;
  }
}

.webkit-intro-slider-image-container {
  grid-column: 1 / -1;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 6;
}

@media (max-width: 1200px){
  .webkit-intro-slider-image-container{
    grid-row-end: 4;
  }
}

.webkit-intro-slider-background-image {
  padding-top: 100vh;
}

@media (max-width: 1200px) {
  .webkit-intro-slider-background-image {
    padding-top: 100vh;
  }
}

@media (max-width: 768px) {
  .webkit-intro-slider-background-image {
    padding-top: 75vh;
  }
}

.webkit-intro-slider-text-container-cell {
  z-index: 10;
  grid-column: span 22 / span 22;
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 5;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 10px 2px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

@media (max-width: 480px){
  .webkit-intro-slider-text-container-cell{
    margin-bottom: 25%;
  }
}

@media (max-width: 768px){
  .webkit-intro-slider-text-container-cell{
    margin-top: 0px;
  }
}

.webkit-intro-spacer {
  padding-top: 5rem;
  grid-column-start: 1;
  grid-column-end: 25;
}

@media (max-width: 1200px){
  .webkit-intro-spacer{
    padding-top: 4rem;
  }
}

.webkit-intro-spacer{
  grid-row-start: 2;
  grid-row-end: 3;
}

.spacer-invisible {
  grid-row-start: 6;
  grid-row-end: 7;
  padding-top: 8rem;
}

@media (max-width: 1200px){
  .spacer-invisible{
    padding-top: 5rem;
  }
}

@media (max-width: 768px){
  .spacer-invisible{
    display: none;
  }
}

.webkit-intro-spacer-blue {
  grid-column-start: 1;
  grid-column-end: 25;
  grid-row-start: 5;
  grid-row-end: 6;
  padding-top: 3rem;
}

@media (max-width: 1600px){
  .webkit-intro-spacer-blue{
    padding-top: 0px;
  }
}

@media (max-width: 768px){
  .webkit-intro-spacer-blue{
    padding-top: 1rem;
  }
}

.webkit-intro-slider-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  grid-column: 1 / -1;
}

@media (max-width: 1200px){
  .webkit-intro-slider-blue{
    padding-top: 3rem;
  }
}

@media (max-width: 768px){
  .webkit-intro-slider-blue{
    padding-top: 0px;
  }
}

.webkit-intro-slider-blue{
  grid-row-start: 3;
  grid-row-end: 7;
}

@media (max-width: 768px){
  .webkit-intro-slider-blue{
    grid-row-end: 6;
  }
}

.webkit-intro-slider-blue{
  margin-bottom: 6rem;
}

@media (max-width: 1200px){
  .webkit-intro-slider-blue{
    margin-bottom: 0px;
  }
}

.webkit-spacer-invisible {
  grid-column-start: 1;
  grid-column-end: 25;
  grid-row-start: 3;
  grid-row-end: 7;
}

@media (max-width: 768px){
  .webkit-spacer-invisible{
    grid-row-end: 6;
  }
}

.webkit-spacer-invisible{
  --tw-bg-opacity: 1;
  background-color: rgba(112, 112, 112, var(--tw-bg-opacity));
  padding-top: 1rem;
}

.webkit-intro-slider-text-container {
  display: flex;
  gap: 2rem;
}

@media (max-width: 768px){
  .webkit-intro-slider-text-container{
    flex-wrap: wrap;
    gap: 0px;
  }
}

.webkit-intro-slider-text-container > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-intro-slider-text-container > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-intro-slider-text-container > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-intro-slider-text-container{
  align-items: center;
  padding: 4rem;
}

@media (max-width: 1600px){
  .webkit-intro-slider-text-container{
    padding: 2.5rem;
  }
}

@media (max-width: 1200px){
  .webkit-intro-slider-text-container{
    padding: 1.5rem;
  }
}

@media (max-width: 768px){
  .webkit-intro-slider-text-container{
    padding: 1rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-slider-text-container{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 0px;
    padding-top: 1.25rem;
  }
}

.webkit-intro-slider-heading {
  margin-bottom: 1rem;
  user-select: none;
  grid-column: span 12 / span 12;
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

@media (max-width: 480px){
  .webkit-intro-slider-heading{
    grid-column: span 16 / span 16;
    grid-column-start: 3;
  }
}

.webkit-intro-slider-heading{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-intro-slider-heading{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-slider-heading{
    font-size: 1.688rem;
  }
}

.webkit-intro-slider-heading{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-intro-slider-text {
  padding-right: 20.833333333333336%;
  user-select: none;
}

@media (max-width: 1600px){
  .webkit-intro-slider-text{
    padding-right: 4.166666666666666%;
  }
}

@media (max-width: 1200px){
  .webkit-intro-slider-text{
    padding: 1rem;
  }
}

@media (max-width: 768px){
  .webkit-intro-slider-text{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 1rem;
  }
}

.webkit-intro-slider-text{
  grid-column: span 14 / span 14;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

@media (max-width: 993px){
  .webkit-intro-slider-text{
    grid-column: span 16 / span 16;
    grid-column-start: 2;
  }
}

@media (max-width: 480px){
  .webkit-intro-slider-text{
    grid-column: span 16 / span 16;
    grid-column-start: 3;
  }
}

.webkit-intro-slider-text{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-intro-slider-text{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-slider-text{
    font-size: 1.688rem;
  }
}

.webkit-intro-slider-text{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-intro-slider-btn {
  align-self: center;
  justify-self: end;
}

@media (max-width: 768px){
  .webkit-intro-slider-btn{
    justify-self: center;
  }
}

.webkit-intro-slider-btn{
  grid-column: span 3 / span 3;
  grid-column-start: 17;
  grid-row-start: 1;
  grid-row-end: 5;
}

@media (max-width: 993px){
  .webkit-intro-slider-btn{
    grid-column: span 4 / span 4;
    grid-column-start: 16;
  }
}

@media (max-width: 480px){
  .webkit-intro-slider-btn{
    padding-top: 8.333333333333332%;
    grid-column: span 18 / span 18;
    grid-column-start: 2;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}

.webkit-intro-slider-btn div {
  vertical-align: middle;
}

.webkit-intro-slider-spacer-top {
  padding-top: 4rem;
  grid-column: span 20 / span 20;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}

@media (max-width: 768px){
  .webkit-intro-slider-spacer-top{
    padding-top: 2rem;
  }
}

.webkit-intro-slider-spacer-bottom {
  padding-top: 4rem;
  grid-column: span 20 / span 20;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 5;
}

@media (max-width: 768px){
  .webkit-intro-slider-spacer-bottom{
    padding-top: 2rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-slider-spacer-bottom{
    grid-row-start: 5;
    grid-row-end: 6;
  }
}

.webkit-intro-slider-next, .webkit-intro-slider-prev {
  position: absolute;
  top: 50%;
  bottom: auto;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  z-index: 10;
  transform: translateY(-50%);
}

@media (max-width: 480px) {
  .webkit-intro-slider-next, .webkit-intro-slider-prev {
    transform: translateY(-80%);
  }
}

.webkit-intro-slider-prev {
  left: 10px;
}

.webkit-intro-slider-next {
  right: 10px;
}

.webkit-intro-slider-outer-cell {
  grid-column-start: 3;
  grid-column-end: 23;
}

@media (max-width: 480px){
  .webkit-intro-slider-outer-cell{
    grid-column-start: 2;
    grid-column-end: 24;
  }
}

@media (max-width: 768px){
  .webkit-intro-slider-outer-cell{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-slider-outer-cell{
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
}

.bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img, .webkit-intro-action-background-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.webkit-btn__small-r, .webkit-btn__small, .webkit-intro-action-button-link i, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-btn {
  cursor: pointer;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 400ms;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-btn__small-r, .webkit-btn__small, .webkit-intro-action-button-link i, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-btn{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-r, .webkit-btn__small, .webkit-intro-action-button-link i, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-btn{
    font-size: 1rem;
  }
}

.webkit-btn__small-r, .webkit-btn__small, .webkit-intro-action-button-link i, .webkit-btn__small-filled-r, .webkit-btn__small-filled, .webkit-btn__big-r, .webkit-btn__big, .webkit-btn{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-btn__big {
  display: block;
  overflow: hidden;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 480px){
  .webkit-btn__big{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.webkit-btn__big{
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-radius: 0px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__big{
    border-width: 2px;
  }
}

.webkit-btn__big{
  border-width: 3px;
}

.webkit-btn__big-r {
  display: block;
  overflow: hidden;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

@media (max-width: 480px){
  .webkit-btn__big-r{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.webkit-btn__big-r{
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 0px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__big-r{
    border-width: 2px;
  }
}

.webkit-btn__big-r{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-width: 3px;
}

.webkit-btn__small-filled {
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small-filled{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-filled{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small-filled > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small-filled > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-filled > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small-filled{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  cursor: default;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small-filled{
    border-width: 2px;
  }
}

.webkit-btn__small-filled{
  border-width: 1px;
}

.webkit-btn__small-filled-r {
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small-filled-r{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-filled-r{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small-filled-r > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small-filled-r > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-filled-r > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small-filled-r{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  cursor: default;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small-filled-r{
    border-width: 2px;
  }
}

.webkit-btn__small-filled-r{
  border-width: 1px;
}

.webkit-btn__small, .webkit-intro-action-button-link i {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small, .webkit-intro-action-button-link i{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small, .webkit-intro-action-button-link i{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small > a.webkit-menu-icon, .webkit-intro-action-button-link i > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small > a.webkit-menu-icon:hover, .webkit-intro-action-button-link i > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small > div.webkit-menu-icon:hover, .webkit-intro-action-button-link i > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small, .webkit-intro-action-button-link i{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 3px;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small, .webkit-intro-action-button-link i{
    border-width: 2px;
  }
}

.webkit-btn__small, .webkit-intro-action-button-link i{
  padding: 1rem;
  border-style: none;
}

.webkit-btn__small-r {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 993px){
  .webkit-btn__small-r{
    height: 1.75rem;
    width: 1.75rem;
  }
}

@media (max-width: 480px){
  .webkit-btn__small-r{
    height: 1.5rem;
    width: 1.5rem;
  }
}

.webkit-btn__small-r > a.webkit-menu-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
}

@media (min-width: 994px){
  .webkit-btn__small-r > a.webkit-menu-icon:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 2px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-r > div.webkit-menu-icon:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
}

.webkit-btn__small-r{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-width: 3px;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 9999px;
  border-width: 8px;
}

@media (max-width: 768px){
  .webkit-btn__small-r{
    border-width: 2px;
  }
}

.webkit-btn__small-r{
  padding: 1rem;
  border-width: 1px;
}

@media (max-width: 768px) {
  .webkit-btn__small-r {
    border-width: 2px;
  }
}

@media (min-width: 994px) {
  .webkit-btn__big:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .webkit-btn__big-r:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }

  .webkit-btn__small-r:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.webkit-intro-action-outer-grid {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: 1fr -webkit-max-content 1fr;
  grid-template-rows: 1fr max-content 1fr;
  overflow: hidden;
}

@media (max-width: 768px) {
  .webkit-intro-action-outer-grid {
    grid-template-rows: -webkit-max-content 1fr 1fr;
    grid-template-rows: max-content 1fr 1fr;
  }
}

.webkit-intro-action-background-image {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 3 / span 3;
  grid-row-start: 1;
}

@media (max-width: 768px){
  .webkit-intro-action-background-image{
    grid-row: span 2 / span 2;
    grid-row-start: 2;
  }
}

.webkit-intro-action-background-image{
  padding-top: 56.25%;
}

@media (max-width: 768px){
  .webkit-intro-action-background-image{
    padding-top: 75%;
  }
}

.webkit-intro-action-text-container {
  grid-column: span 9 / span 9;
  grid-column-start: 3;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
}

@media (max-width: 993px){
  .webkit-intro-action-text-container{
    grid-column: span 12 / span 12;
    grid-column-start: 4;
  }
}

@media (max-width: 768px){
  .webkit-intro-action-text-container{
    padding-bottom: 1rem;
    padding-top: 12.5%;
    grid-column: span 17 / span 17;
    grid-column-start: 3;
    grid-row: span 1 / span 1;
    grid-row-start: 1;
  }
}

.webkit-intro-action-text-grid {
  display: grid;
  padding-top: 8.333333333333332%;
  padding-bottom: 8.333333333333332%;
}

@media (max-width: 768px){
  .webkit-intro-action-text-grid{
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.webkit-intro-action-text-grid{
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content max-content;
}

.webkit-intro-action-heading {
  grid-row: span 1 / span 1;
  grid-row-start: 1;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-intro-action-heading{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-action-heading{
    font-size: 1.688rem;
  }
}

.webkit-intro-action-heading2 {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-intro-action-heading2{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-action-heading2{
    font-size: 1.688rem;
  }
}

.webkit-intro-action-heading2{
  --tw-text-opacity: 1;
  color: rgba(112, 112, 112, var(--tw-text-opacity));
}

.webkit-intro-action-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  grid-row: span 1 / span 1;
  grid-row-start: 2;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .9375rem;
}

@media (max-width: 993px){
  .webkit-intro-action-text{
    font-size: .7rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-action-text{
    font-size: .7rem;
  }
}

.webkit-intro-action-button {
  grid-row: span 1 / span 1;
  grid-row-start: 3;
  display: inline-block;
  margin-top: 4.166666666666666%;
}

.webkit-intro-action-button-link {
  display: inline-block;
}

.webkit-intro-action-button-text {
  display: inline-block;
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: .625rem;
}

@media (max-width: 993px){
  .webkit-intro-action-button-text{
    font-size: .5rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-action-button-text{
    font-size: .5rem;
  }
}

.webkit-intro-action-button-text{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

@media (max-width: 768px){
  .webkit-intro-action-button-text{
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
}

.bg-img__square, .bg-img__2-1, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img__contain {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.img-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

.bg-img__16-9 {
  padding-top: 56.25%;
}

.bg-img__16-10 {
  padding-top: 62.5%;
}

.bg-img__4-3 {
  padding-top: 75%;
}

.bg-img__2-1 {
  padding-top: 50%;
}

.bg-img__square {
  padding-top: 100%;
}

.bg-img__round {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}

.bg-img__center-top {
  background-position: top;
}

.webkit-intro-video-container {
  overflow: hidden;
  display: grid;
  height: 100%;
  padding-bottom: 4.166666666666666%;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-rows: max-content max-content max-content;
}

.webkit-intro-video {
  grid-column: span 24 / span 24;
  grid-column-start: 1;
  grid-row: span 3 / span 3;
  grid-row-start: 1;
  width: 100%;
  height: auto;
}

.webkit-intro-video-aspect {
  height: 100%;
  position: relative;
  padding-top: 41.66666666666667%;
}

.webkit-intro-video-player {
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.webkit-intro-video-text-container-cell {
  z-index: 10;
  grid-column: span 18 / span 18;
  grid-column-start: 4;
  grid-row: span 2 / span 2;
  grid-row-start: 3;
}

@media (max-width: 768px){
  .webkit-intro-video-text-container-cell{
    grid-column: span 20 / span 20;
    grid-column-start: 3;
  }
}

.webkit-intro-video-text-container-cell{
  margin-top: 5rem;
}

@media (max-width: 993px){
  .webkit-intro-video-text-container-cell{
    margin-top: 4rem;
  }
}

@media (max-width: 768px){
  .webkit-intro-video-text-container-cell{
    margin-top: 6rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-video-text-container-cell{
    margin-top: 8rem;
  }
}

.webkit-intro-video-text-container-cell{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 10px 2px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 1.5rem;
}

.webkit-intro-video-text-container {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  justify-self: start;
  text-align: left;
  width: 100%;
}

.webkit-intro-video-heading {
  margin-bottom: 1rem;
  user-select: none;
  grid-column: span 12 / span 12;
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

@media (max-width: 480px){
  .webkit-intro-video-heading{
    grid-column: span 16 / span 16;
    grid-column-start: 3;
  }
}

.webkit-intro-video-heading{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-intro-video-heading{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-video-heading{
    font-size: 1.688rem;
  }
}

.webkit-intro-video-heading{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-intro-video-text {
  user-select: none;
  grid-column: span 14 / span 14;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

@media (max-width: 993px){
  .webkit-intro-video-text{
    grid-column: span 13 / span 13;
    grid-column-start: 2;
  }
}

@media (max-width: 480px){
  .webkit-intro-video-text{
    grid-column: span 16 / span 16;
    grid-column-start: 3;
  }
}

.webkit-intro-video-text{
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-intro-video-text{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-intro-video-text{
    font-size: 1rem;
  }
}

.webkit-intro-video-text{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.webkit-intro-video-btn {
  align-self: center;
  justify-self: end;
  grid-column: span 3 / span 3;
  grid-column-start: 17;
  grid-row-start: 1;
  grid-row-end: 5;
}

@media (max-width: 993px){
  .webkit-intro-video-btn{
    grid-column: span 4 / span 4;
    grid-column-start: 16;
  }
}

@media (max-width: 480px){
  .webkit-intro-video-btn{
    padding-top: 8.333333333333332%;
    grid-column: span 18 / span 18;
    grid-column-start: 2;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}

.webkit-intro-video-btn div {
  vertical-align: middle;
}

.webkit-intro-video-spacer-top {
  padding-top: 4.166666666666666%;
  grid-column: span 20 / span 20;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}

.webkit-intro-video-spacer-bottom {
  padding-top: 4.166666666666666%;
  grid-column: span 20 / span 20;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 5;
}

@media (max-width: 480px){
  .webkit-intro-video-spacer-bottom{
    grid-row-start: 5;
    grid-row-end: 6;
  }
}

.webkit-introTextBild-container {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  height: calc(100vh - 214px);
}

@media (max-width: 768px) {
  .webkit-introTextBild-container {
    height: calc(100vh - 200px);
  }
}

.webkit-introTextBild-image {
  width: 100%;
  background-size: cover;
  grid-column-start: 1;
  grid-column-end: 25;
  grid-row-start: 1;
  grid-row-end: 4;
  background-repeat: no-repeat;
}

.webkit-introTextBild-text {
  grid-column-start: 14;
  grid-column-end: 23;
}

@media (max-width: 1200px){
  .webkit-introTextBild-text{
    grid-column-start: 12;
  }
}

@media (max-width: 768px){
  .webkit-introTextBild-text{
    grid-column-start: 3;
  }
}

.webkit-introTextBild-text{
  grid-row-start: 2;
  grid-row-end: 3;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  opacity: 0.8;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.webkit-introTextBild-heading {
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-size: 3.25rem;
}

@media (max-width: 993px){
  .webkit-introTextBild-heading{
    font-size: 2.8125rem;
  }
}

@media (max-width: 480px){
  .webkit-introTextBild-heading{
    font-size: 1.688rem;
  }
}

.webkit-introTextBild-heading{
  font-weight: 700;
  padding-bottom: 1.5rem;
}

.webkit-introTextBild-inner-text {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 768px){
  .webkit-introTextBild-inner-text{
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.webkit-introTextBild-content {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}

@media (max-width: 993px){
  .webkit-introTextBild-content{
    font-size: 1.25rem;
  }
}

@media (max-width: 480px){
  .webkit-introTextBild-content{
    font-size: 1rem;
  }
}

body#old-browsers .webkit-header {
  position: static;
}

body#old-browsers .browsers-list {
  display: inline-block;
  width: auto;
  margin: 1.5rem auto;
  -moz-columns: 2;
  columns: 2;
  list-style: none;
}

body#old-browsers .browsers-list > li {
  display: block;
  text-align: left;
  padding: 0;
}

body#old-browsers .browsers-list > li a {
  display: block;
  padding: 0.5rem;
  overflow: hidden;
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
}

body#old-browsers .browsers-list > li a:hover {
  background-color: rgba(0, 0, 0, 0.16);
}

body#old-browsers .browsers-list > li + li {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  body#old-browsers .browsers-list {
    -moz-columns: 1;
    columns: 1;
  }
}

body#old-browsers .browser-logo {
  display: inline-block;
  width: 64px;
  height: auto;
  margin-right: 1rem;
  vertical-align: middle;
}

body#old-browsers .webkit-header,
body#old-browsers .webkit-text,
body#old-browsers .webkit-main-footer,
body#old-browsers .webkit-footer,
body#old-browsers .webkit-heading {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

body#old-browsers .webkit-header-logo {
  -ms-grid-column: 2;
  -ms-grid-column-span: 8;
}

body#old-browsers .webkit-text-cell {
  -ms-grid-column: 5;
  -ms-grid-column-span: 14;
}

body#old-browsers .webkit-footer-module-contact {
  -ms-grid-column: 19;
  -ms-grid-column-span: 4;
}

body#old-browsers .webkit-footer-cell {
  -ms-grid-column: 3;
  -ms-grid-column-span: 20;
}

body#old-browsers .webkit-footer-module-logo-text {
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
}

body#old-browsers .webkit-heading-cell {
  -ms-grid-column: 5;
  -ms-grid-column-span: 14;
}